import styled from "styled-components"
import {GeorgiaTechLogo, KPULogo} from "../icons/Icons";
import screenSizes from "../screenSizes";
import {useEffect, useState} from "react";
import {LazyMotion, domAnimation, m} from "framer-motion"

const StyledEducation = styled(m.ul)`
    display: flex;
    flex-direction: column;
`
const StyledEducationItem = styled.li`
    display: flex;
    align-items: center;
    
    :first-of-type {
        margin-bottom: 10px;
    }

    & > span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
    }
`

const StyledEducationSchool = styled.p`
    font-size: ${props => props.theme.fonts.sizes.sm};
`

const StyledEducationDegree = styled.p`
    font-style: italic;
    font-weight: 300;

    ${screenSizes.below768} {
        font-size: calc(${props => props.theme.fonts.sizes.sm} - 2px);
    }
`

const StyledEducationYears = styled.p`
    font-size: calc(${props => props.theme.fonts.sizes.sm} - 4px);
    font-weight: 300;
`

// const StyledEducationDetails = styled.ul`
//   margin-top: 10px;
//   padding-left: 20px;
//   font-weight: 300;
// `
//
// const StyledEducationDetail = styled.li`
//   margin-bottom: 5px;
// `

type EducationProps = {
    isInView?: boolean
}

const Education = ({isInView = true}: EducationProps) => {

    const [viewed, setViewed] = useState(isInView)

    useEffect(() => {
        if (isInView && !viewed) {
            setViewed(isInView)
        }
    }, [isInView, viewed])

    return (
        <LazyMotion features={domAnimation}>
            <StyledEducation
                animate={{opacity: viewed ? 1 : 0, y: viewed ? 0 : 20}}
                transition={{ease: "easeInOut", duration: 0.6}}
            >
                <StyledEducationItem>
                    <GeorgiaTechLogo/>
                    <span>
                        <StyledEducationSchool>Georgia Tech</StyledEducationSchool>
                        <StyledEducationDegree>Master of Computer Science - OMSCS</StyledEducationDegree>
                        <StyledEducationYears>August 2024 - Present</StyledEducationYears>
                    </span>
                </StyledEducationItem>
                <StyledEducationItem>
                    <KPULogo/>
                    <span>
                        <StyledEducationSchool>Kwantlen Polytechnic University</StyledEducationSchool>
                        <StyledEducationDegree>Bachelor of Technology</StyledEducationDegree>
                        <StyledEducationYears>January 2016 - December 2020</StyledEducationYears>
                    </span>
                </StyledEducationItem>
            </StyledEducation>
        </LazyMotion>
    )
}

export default Education